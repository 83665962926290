<template>
  <div class="hello">
    <div class="limiter">
      <div class="container-table100">
        <div class="wrap-table100">
          <div class="volunteer">
            <div id="nav">
              <router-link
                to="/"
                class="homeAreaLink"
              >
                <div class="isIcon">
                  <svg
                    class="svg-icon homeArea"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z"
                    />
                  </svg>
                </div>
              </router-link>
              <strong class="bloDet text-white">اضافة بيانات المتبرع</strong>
              <div class="Buy_Coffee">
                <div class="innerBuy_Coffee">
                  <a
                    href="https://twitter.com/ashmawisami?ref_src=twsrc%5Etfw"
                    class="twitter-follow-button"
                    data-show-count="false"
                  >Follow @ashmawisami</a>
                  <div class="btn-container">
                    <a
                      title="Support me on ko-fi.com"
                      class="kofi-button"
                      style="background-color:#8f1f1f;"
                      href="https://ko-fi.com/D1D81LD43"
                      target="_blank"
                    >
                      <span class="kofitext">
                        اشتري لي قهوة
                        <img
                          src="https://cdn.ko-fi.com/cdn/cup-border.png"
                          class="kofiimg"
                        >
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>


            <div
              v-if="isCreate"
              class="volunteer_inner"
            >
              <div
                class="alert alert-success text-center"
                role="alert"
              >
                <strong>وَمَنْ أَحْيَاهَا فَكَأَنَّمَا أَحْيَا النَّاسَ جَمِيعًا ۚ</strong>
                <small class="m-2">(سورة المائدة)</small>
              </div>

              <div v-if="errors.length">
                <div
                  class="alert alert-danger"
                  role="alert"
                >
                  <b>رجاء تصحيح الاخطاء</b>
                  <ul class="m-0">
                    <li
                      v-for="error in errors"
                      :key="error['.key']"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputName">
                    <span class="required">*</span> اسم المتبرع
                  </label>
                  <input
                    id="inputName"
                    v-model="name"
                    type="name"
                    class="form-control"
                    placeholder="فاعل خير"
                    required
                  >
                </div>
                <div class="form-group col-md-6">
                  <label for="inputNumber">
                    <span class="required">*</span> رقم الهاتف
                  </label>
                  <input
                    id="inputNumber"
                    v-model="phone"
                    class="form-control"
                    placeholder="رقم الهاتف"
                    minlength="0"
                    min="0"
                    type="number"
                    required
                  >
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-2">
                  <label for="inputCountry">
                    <span class="required">*</span> الدوله
                  </label>
                  <select
                    id="inputCountry"
                    class="form-control"
                    required
                    disabled
                  >
                    <option
                      value="مصر"
                      selected
                    >
                      مصر
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-3">
                  <label for="inputCity">
                    <span class="required">*</span> المحافظة
                  </label>
                  <select
                    id="inputCity"
                    v-model="city"
                    class="form-control"
                    @change="onChange($event)"
                  >
                    <option
                      value
                      hidden
                      disabled
                      selected
                    >
                      اختـــر محافظة
                    </option>
                    <option value="القاهرة">
                      القاهرة
                    </option>
                    <option value="الجيزة">
                      الجيزة
                    </option>
                    <option value="الشرقية">
                      الشرقية
                    </option>
                    <option value="الدقهلية">
                      الدقهلية
                    </option>
                    <option value="البحيرة">
                      البحيرة
                    </option>
                    <option value="المنيا">
                      المنيا
                    </option>
                    <option value="القليوبية">
                      القليوبية
                    </option>
                    <option value="الإسكندرية">
                      الإسكندرية
                    </option>
                    <option value="الغربية">
                      الغربية
                    </option>
                    <option value="سوهاج">
                      سوهاج
                    </option>
                    <option value="أسيوط">
                      أسيوط
                    </option>
                    <option value="المنوفية">
                      المنوفية
                    </option>
                    <option value="كفر الشيخ">
                      كفر الشيخ
                    </option>
                    <option value="الفيوم">
                      الفيوم
                    </option>
                    <option value="قنا">
                      قنا
                    </option>
                    <option value="بني سويف">
                      بني سويف
                    </option>
                    <option value="أسوان">
                      أسوان
                    </option>
                    <option value="دمياط">
                      دمياط
                    </option>
                    <option value="الإسماعيلية">
                      الإسماعيلية
                    </option>
                    <option value="الأقصر">
                      الأقصر
                    </option>
                    <option value="بورسعيد">
                      بورسعيد
                    </option>
                    <option value="السويس">
                      السويس
                    </option>
                    <option value="مطروح">
                      مطروح
                    </option>
                    <option value="شمال سيناء">
                      شمال سيناء
                    </option>
                    <option value="البحر الأحمر">
                      البحر الأحمر
                    </option>
                    <option value="الوادي الجديد">
                      الوادي الجديد
                    </option>
                    <option value="جنوب سيناء">
                      جنوب سيناء
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-4">
                  <label for="inputRegion"><span class="required">*</span> المدينة / المركز / الحي</label>
                  <select
                    id="inputRegion"
                    v-model="region"
                    class="form-control"
                    :disabled="!city"
                    required
                  >
                    <option
                      value="all"
                      selected
                    >
                      تحديد الكل
                    </option>
                    <option
                      v-for="item in allRegion"
                      :key="item['.region']"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-3">
                  <label for="inputBlood">
                    <span class="required">*</span> فصيلة الدم
                  </label>
                  <select
                    id="inputBlood"
                    v-model="bloodType"
                    class="form-control"
                    required
                  >
                    <option
                      value
                      disabled
                      hidden
                    >
                      اختــر فصيلة الدم
                    </option>
                    <option value="Op">
                      O+
                    </option>
                    <option value="Om">
                      O-
                    </option>
                    <option value="Bp">
                      B+
                    </option>
                    <option value="Bm">
                      B-
                    </option>
                    <option value="Ap">
                      A+
                    </option>
                    <option value="Am">
                      A-
                    </option>
                    <option value="ABp">
                      AB+
                    </option>
                    <option value="ABm">
                      AB-
                    </option>
                  </select>
                </div>
              </div>

              <div class="contactArea">
                خيـــارات التواصل
              </div>

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="inputEmail4">البريد الالكتروني</label>
                  <input
                    id="inputEmail4"
                    v-model="email"
                    type="email"
                    class="form-control"
                    placeholder="البريد الالكتروني"
                  >
                </div>

                <div class="form-group col-md-4">
                  <label for="inputBlood">وقت الإتصال</label>
                  <select
                    id="inputBlood"
                    v-model="bloodTime"
                    class="form-control"
                  >
                    <option
                      value="24 ساعة"
                      selected
                    >
                      24 ساعة
                    </option>
                    <option value="من 8 صباحاً  الى 3 عصراً">
                      من 8 صباحاً الى 3 عصراً
                    </option>
                    <option value="من3 الى 11 مساءً">
                      من3 الى 11 مساءً
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-4 contactType">
                  <label
                    for="inputContactType"
                    class="inputContactType"
                  >نوع الإتصال</label>
                  <select
                    id="inputBlood"
                    v-model="contactType"
                    class="form-control"
                  >
                    <option value="all">
                      اتصـــال او رســائل
                    </option>
                    <option value="sms">
                      رســائل
                    </option>
                    <option value="call">
                      اتصـــال
                    </option>
                  </select>
                </div>
              </div>

              <div class="addIsClass text-center">
                <button
                  type="submit"
                  class="btn btn-primary createVolunteer"
                  @click="addBenefactor()"
                >
                  إضافة البيانات
                </button>
                <h6><span class="badge badge-warning">بالضغط فإنك توافق على شروط استخدام و سياسة الخصوصية</span></h6>
              </div>
            </div>

            <div
              v-if="!isCreate"
              class="volunteer_inner thanks text-center"
            >
              <h4 class="text-success">
                شكراً لك تم تسجيلك بنجاح
              </h4>
              <button
                class="btn btn-primary createVolunteer"
                @click="otherAddBenefactor()"
              >
                اضافة بيانات متبرع اخر
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import { isBenefactor } from '../firebase';

export default {
  data() {
    return {
      name: '',
      phone: '',
      city: '',
      country: 'مصر',
      bloodType: '',
      email: '',
      bloodTime: '24 ساعة',
      contactType: 'call',

      allRegion: [],
      region: 'all',
      isCreate: true,
      errors: [],
    };
  },

  methods: {
    addBenefactor() {
      this.errors = [];

      if (!this.name) {
        this.errors.push('الاسم مطلوب');
      }

      if (!this.phone) {
        this.errors.push('الهاتف مطلوب');
      }

      if (!this.city) {
        this.errors.push('رجاء كتابة المحافظة');
      }

      if (!this.bloodType) {
        this.errors.push('رجاء ادخل فصيلة الدم');
      }

      if (this.email) {
        if (!this.validEmail(this.email)) {
          this.errors.push('رجاء تصحيح الإيميل');
        }
      }

      if (this.errors.length === 0) {
        isBenefactor.push({
          name: this.name,
          phone: this.phone,
          city: this.city,
          region: this.region,
          country: this.country,
          bloodType: this.bloodType,
          email: this.email,
          phonbloodTimee: this.bloodTime,
          contactType: this.contactType,
          isReal: false,
          created_at: firebase.database.ServerValue.TIMESTAMP,
        });
        this.isCreate = false;
      }
    },
    otherAddBenefactor() {
      this.name = '';
      this.phone = '';
      this.city = '';
      this.region = 'all';
      this.country = 'مصر';
      this.bloodType = '';
      this.email = '';
      this.bloodTime = '24 ساعة';
      this.contactType = 'call';
      this.isCreate = true;
    },
    // eslint-disable-next-line
    validEmail: function(email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    onChange(event) {
      const v = event.target.value;
      this.region = 'all';

      if (v == 'القاهرة') {
        this.allRegion = [
          'المرج',
          'المطرية',
          'عين شمس',
          'السلام',
          'النزهة',
          'مصر الجديدة',
          'مدينة نصر',
          'منشأة ناصر',
          'الوايلي',
          'باب الشعرية',
          'الموسكى',
          'الأزبكية',
          'عابدين',
          'بولاق',
          'الزيتون',
          'حدائق القبة',
          'الزاوية الحمراء',
          'الشرابية',
          'شبرا',
          'روض الفرج',
          'السيدة زينب',
          'مصر القديمة',
          'الخليفة',
          'المقطم',
          'البساتين',
          'دار السلام',
          'المعادي',
          'طرة',
          'حلوان',
          'التبين',
        ];
      }

      if (v == 'الجيزة') {
        this.allRegion = ['منشأة القناطر', 'أوسيم', 'كرداسة', 'أبو النمرس', 'الحوامدية', 'البدرشين', 'العياط', 'الصف', 'أطفيح'];
      }

      if (v == 'الشرقية') {
        this.allRegion = ['مدينة الزقازيق', 'الحسينية (الشرقية)', 'فاقوس', 'بلبيس', 'منيا القمح', 'أبو حماد', 'العاشر من رمضان', 'الصالحية الجديدة', 'كفر صقر', 'أبو كبير', 'القنايات', 'مشتول السوق', 'ديرب نجم', 'الإبراهيمية', 'ههيا', 'القرين', 'صان الحجر', 'العزيزية'];
      }

      if (v == 'الدقهلية') {
        this.allRegion = ['المنصورة', 'المنزلة', 'الجمالية', 'دكرنس', 'منية النصر', 'ميت سلسيل', 'ميت غمر', 'أجا', 'طلخا', 'بلقاس', 'السنبلاوين', 'شربين', 'بني عبيد', 'المطرية', 'نبروه', 'جمصة', 'الكردي'];
      }

      if (v == 'البحيرة') {
        this.allRegion = ['رشيد', 'شبراخيت', 'ايتاي البارود', 'أبو حمص', 'حوش عيسى', 'كفر الدوار', 'الدلنجات', 'كوم حمادة', 'دمنهور', 'المحمودية', 'إدكو', 'أبو المطامير', 'الرحمانية', 'النوبارية الجديدة', 'وادي النطرون'];
      }

      if (v == 'المنيا') {
        this.allRegion = ['مغاغة', 'بني مزار', 'مطاي', 'سمالوط', 'مدينة المنيا', 'أبو قرقاص', 'ملوي', 'دير مواس', 'العدوة'];
      }

      if (v == 'القليوبية') {
        this.allRegion = ['بنها', 'قليوب', 'القناطر الخيرية', 'شبرا الخيمة', 'الخانكة', 'كفر شكر', 'شبين القناطر', 'طوخ', 'العبور (قليوبية)', 'قها (قليوبية)', 'الخصوص'];
      }

      if (v == 'الإسكندرية') {
        this.allRegion = ['المنتزة', 'الرمل', 'كرموس', 'محرم بك', 'العطارين', 'الجمرك', 'العجمي', 'العامرية', 'سيدي جابر', 'المنشية', 'مينا البصل', 'العامرية', 'الدخيلة', 'برج العرب'];
      }

      if (v == 'الغربية') {
        this.allRegion = ['كفر الزيات', 'السنطة', 'المحلة الكبرى', 'بسيون', 'زفتى', 'سمنود', 'طنطا', 'قطور'];
      }

      if (v == 'سوهاج') {
        this.allRegion = ['أخميم', 'دار السلام', 'مراغة', 'طهطا', 'طما', 'جهينة', 'المنشأة', 'جرجا', 'البلينا'];
      }

      if (v == 'أسيوط') {
        this.allRegion = ['مدينة أسيوط', 'ديروط', 'القوصية', 'أبنوب', 'منفلوط', 'الفتح', 'الغنايم', 'ساحل سليم', 'البداري', 'صدفا'];
      }

      if (v == 'المنوفية') {
        this.allRegion = ['شبين الكوم', 'منوف', 'أشمون', 'قويسنا', 'تلا'];
      }

      if (v == 'كفر الشيخ') {
        this.allRegion = ['فوه', 'مطوبس', 'قلين', 'سيدي سالم', 'الرياض', 'بيلا', 'الحامول', 'البرلس'];
      }

      if (v == 'الفيوم') {
        this.allRegion = ['مدينة الفيوم', 'سنورس', 'ابشواى', 'اطسا', 'طامية', 'يوسف الصديق'];
      }

      if (v == 'قنا') {
        this.allRegion = ['مدينة قنا', 'أبو تشت', 'فرشوط', 'نجع حمادي', 'الوقف', 'دشنا', 'قفط', 'قوص', 'نقادة'];
      }

      if (v == 'بني سويف') {
        this.allRegion = ['الواسطى', 'مدينة بني سويف', 'ناصر (بوش)', 'إهناسيا', 'ببا', 'سمسطا', 'الفشن', 'بني سويف الجديدة'];
      }

      if (v == 'أسوان') {
        this.allRegion = ['مدينة أسوان', 'دراو', 'كوم أمبو', 'نصر النوبة'];
      }

      if (v == 'دمياط') {
        this.allRegion = ['مدينة دمياط', 'دمياط الجديدة', 'فارسكور', 'كفر سعد', 'الزرقا', 'كفر البطيخ', 'رأس البر'];
      }

      if (v == 'الإسماعيلية') {
        this.allRegion = ['مدينة الإسماعيلية', 'التل الكبير', 'فايد', 'القنطرة شرق', 'القنطرة غرب', 'أبوصوير', 'القصاصين'];
      }

      if (v == 'الأقصر') {
        this.allRegion = ['مدينة الأقصر', 'مدينة الأقصر الجديدة', 'مدينة طيبة الجديدة', 'الزينية', 'البياضية', 'القرنة', 'أرمنت', 'الطود', 'إسنا'];
      }

      if (v == 'بورسعيد') {
        this.allRegion = ['بورفؤاد', 'المناخ', 'الضواحي', 'الزهور', 'العرب'];
      }

      if (v == 'السويس') {
        this.allRegion = ['مدينة السويس', 'الأربعين', 'عتاقة', 'الجناين', 'فيصل'];
      }

      if (v == 'مطروح') {
        this.allRegion = ['العلمين', 'الضبعة', 'الحمام', 'مرسى مطروح', 'النجيلة', 'براني', 'السلوم', 'سيوة'];
      }

      if (v == 'شمال سيناء') {
        this.allRegion = ['بئر العبد', 'نخل', 'الحسنة', 'العريش', 'الشيخ زويد', 'رفح'];
      }

      if (v == 'البحر الأحمر') {
        this.allRegion = ['الغردقة', 'سفاجا', 'مرسى علم', 'القصير', 'رأس غارب', 'شلاتين'];
      }

      if (v == 'الوادي الجديد') {
        this.allRegion = ['الخارجة', 'باريس', 'بلاط', 'الداخلة', 'الفرافرة'];
      }

      if (v == 'جنوب سيناء') {
        this.allRegion = ['شرم الشيخ', 'دهب', 'الطور', 'طابا', 'نويبع', 'سانت كاترين', 'أبو رديس', 'أبو زنيمة', 'رأس سدر'];
      }
    },
  },

};
</script>

<style lang="scss" scoped>
  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  .isIcon {
    float: right;
    width: 40px;
    height: 40px;
    line-height: 35px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .isIcon:hover {
    background: rgba(0, 0, 0, 0.39);
  }
  .svg-icon {
    width: 26px;
    fill: #fff;
  }
  .bloDet {
    line-height: 40px;
  }
  .volunteer {
    &_inner {
      padding: 30px;
      overflow: hidden;
      background-color: #fff;
      margin: auto;
      text-align: right;
      .form-row {
        margin-bottom: 10px;
      }
      span.required {
        color: red;
      }
      label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .contactArea {
        font-weight: bold;
        text-align: center;
        position: relative;
        margin: 30px 0;
        padding-bottom: 10px;
        color: #801336;
        letter-spacing: -0.5px;
        &::before {
          margin: 0 10px;
          width: 70px;
          height: 1px;
          content: "";
          background-color: #ddd;
          position: relative;
          display: inline-block;
        }
        &::after {
          margin: 0 10px;
          width: 70px;
          height: 1px;
          content: "";
          background-color: #ddd;
          position: relative;
          display: inline-block;
        }
      }
      .inputContactType {
        margin-bottom: 15px;
        display: block;
      }
      .createVolunteer {
        padding: 5px 40px;
        margin-top: 15px;
        background-color: #801336 !important;
        border-color: #2d132c;
        &:hover {
          background-color: #c72c41 !important;
          border-color: #ee4540;
        }
      }
      .contactType font {
        margin-left: 5px;
      }
      .contactType input {
        margin-left: 20px;
      }
      .addIsClass {
        span.badge {
          display: inline;
          padding: 0px 5px;
          line-height: 0;
          font-size: 10px;
        }
      }
    }
  }
  .wrap-table100 {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  }
  @media screen and (max-width: 768px) {
    .contactArea {
      &::before {width: 30px !important; }
      &::after {width: 30px !important;}
    }
  }
</style>
